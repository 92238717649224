import { clone, omit } from "lodash";

import {
  object, number, string, array
} from "../../../../../../util/yup";

export const defaultRows = [
  {
    id: "Standard", total: 0, monday: "", tuesday: "", wednesday: "", thursday: "", friday: "", saturday: "0", sunday: "0"
  },
];

export const otRoles = [
  {
    id: "Overtime", total: 0, monday: "", tuesday: "", wednesday: "", thursday: "", friday: "", saturday: "", sunday: ""
  },
  {
    id: "Double Time", total: 0, monday: "", tuesday: "", wednesday: "", thursday: "", friday: "", saturday: "", sunday: ""
  },
];

export const columns = [
  {
    field: "id", headerName: "Hours", width: 120, sortable: false
  },
  {
    field: "monday", headerName: "Mon.", width: 73, editable: true, sortable: false, align: "center", headerAlign: 'center',
  },
  {
    field: "tuesday", headerName: "Tue.", width: 73, editable: true, sortable: false, align: "center", headerAlign: 'center',
  },
  {
    field: "wednesday", headerName: "Wed.", width: 73, editable: true, sortable: false, align: "center", headerAlign: 'center',
  },
  {
    field: "thursday", headerName: "Thu.", width: 73, editable: true, sortable: false, align: "center", headerAlign: 'center',
  },
  {
    field: "friday", headerName: "Fri.", width: 73, editable: true, sortable: false, align: "center", headerAlign: 'center',
  },
  {
    field: "saturday", headerName: "Sat.", width: 73, editable: true, sortable: false, align: "center", headerAlign: 'center',
  },
  {
    field: "sunday", headerName: "Sun.", width: 73, editable: true, sortable: false, align: "center", headerAlign: 'center',
  },
  {
    field: "total", headerName: "Total", type: "number", width: 84, sortable: false, align: "center", headerAlign: 'center',
  },
];

export const excludeKeys = ([key]) => {
  return !["id", "total", "error"].includes(key);
};

export const validationSchema = object().shape({
  timesheetHours: array().of(object())
    .test({
      test(rows) {
        for (const row of Object.values(rows)) {
          if (row.error) {
            return this.createError({ message: row.error });
          }
        }
        const notFilled = Object.entries(rows[0])
          .filter(excludeKeys)
          .some(([, value]) => !Boolean(value));
        if (notFilled) {
          return this.createError({ message: "Standard Hours row must be filled" });
        }
        return true;
      }
    }),
  additionalBilling: number()
    .nullable(true)
    .min(0)
    .max(5000)
    .label("Additional Billing")
    .when("additionalBillingReason", {
      is: (val) => val,
      then: number().required()
    }),
  additionalBillingReason: string()
    .nullable(true)
    .max(1000)
    .label("Additional Billing Explanation")
    .when("additionalBilling", {
      is: (val) => val,
      then: string().required()
    }),
}, ['additionalBilling', 'additionalBillingReason']);

export function buildInitialValues(payPeriod) {
  const { attributes } = payPeriod;

  const timesheetHours = [];

  // For zero hour weeks, we still need to show the row
  if (attributes?.submittedAt !== null) {
    timesheetHours.push({
      id: "Standard",
      total: parseFloat(attributes.standardHours ?? "0"),
      monday: attributes.standardHoursDetails?.[0] ?? "N/A",
      tuesday: attributes.standardHoursDetails?.[1] ?? "N/A",
      wednesday: attributes.standardHoursDetails?.[2] ?? "N/A",
      thursday: attributes.standardHoursDetails?.[3] ?? "N/A",
      friday: attributes.standardHoursDetails?.[4] ?? "N/A",
      saturday: attributes.standardHoursDetails?.[5] ?? "N/A",
      sunday: attributes.standardHoursDetails?.[6] ?? "N/A",
    });
  } else {
    // If hours are not submitted, use default row
    timesheetHours.push(defaultRows[0]);
  }

  if (attributes?.nonStandardHours !== null && parseFloat(attributes.nonStandardHours) > 0) {
    timesheetHours.push({
      id: "Overtime",
      total: parseFloat(attributes.nonStandardHours),
      monday: attributes.nonStandardHoursDetails?.[0] ?? "N/A",
      tuesday: attributes.nonStandardHoursDetails?.[1] ?? "N/A",
      wednesday: attributes.nonStandardHoursDetails?.[2] ?? "N/A",
      thursday: attributes.nonStandardHoursDetails?.[3] ?? "N/A",
      friday: attributes.nonStandardHoursDetails?.[4] ?? "N/A",
      saturday: attributes.nonStandardHoursDetails?.[5] ?? "N/A",
      sunday: attributes.nonStandardHoursDetails?.[6] ?? "N/A",
    });
  }

  if (attributes?.doubleTimeHours !== null && parseFloat(attributes.doubleTimeHours) > 0) {
    timesheetHours.push({
      id: "Double Time",
      total: parseFloat(attributes.doubleTimeHours),
      monday: attributes.doubleTimeHoursDetails?.[0] ?? "N/A",
      tuesday: attributes.doubleTimeHoursDetails?.[1] ?? "N/A",
      wednesday: attributes.doubleTimeHoursDetails?.[2] ?? "N/A",
      thursday: attributes.doubleTimeHoursDetails?.[3] ?? "N/A",
      friday: attributes.doubleTimeHoursDetails?.[4] ?? "N/A",
      saturday: attributes.doubleTimeHoursDetails?.[5] ?? "N/A",
      sunday: attributes.doubleTimeHoursDetails?.[6] ?? "N/A",
    });
  }

  return {
    timesheetHours,
    additionalBilling: attributes?.additionalBilling && parseFloat(attributes.additionalBilling),
    additionalBillingReason: attributes?.additionalBillingReason,
  };
}

function rowToHoursArray(row) {
  return Object.entries(row)
    .filter(excludeKeys)
    .map(([, value]) => value || "0");
}

export const transformValues = (values) => {
  const newValues = clone(omit(values, ["timesheetHours"]));
  const { timesheetHours, additionalBilling } = values;

  const standardHours = timesheetHours.find(({ id }) => id === "Standard");
  if (standardHours.total === 0) {
    newValues.standardHours = null;
  } else {
    newValues.standardHours = standardHours.total;
  }
  // Always set standard hours row
  newValues.standardHoursDetails = rowToHoursArray(timesheetHours[0]);

  const overtimeHours = timesheetHours.find(({ id }) => id === "Overtime");
  if (!overtimeHours || overtimeHours.total === 0) {
    newValues.nonStandardHours = null;
    newValues.nonStandardHoursDetails = null;
  } else {
    newValues.nonStandardHours = overtimeHours.total;
    newValues.nonStandardHoursDetails = rowToHoursArray(overtimeHours);
  }

  const doubleTimeHours = timesheetHours.find(({ id }) => id === "Double Time");
  if (!doubleTimeHours || doubleTimeHours.total === 0) {
    newValues.doubleTimeHours = null;
    newValues.doubleTimeHoursDetails = null;
  } else {
    newValues.doubleTimeHours = doubleTimeHours.total;
    newValues.doubleTimeHoursDetails = rowToHoursArray(doubleTimeHours);
  }

  if (additionalBilling === 0) newValues.additionalBilling = null;

  return newValues;
};
