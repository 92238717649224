import { Tooltip } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";

import { openModal } from "../../../../../actions/shared/uiActions";
import Button from "../../../../forms/custom/Button";
import SubmitHoursModal from "../modals/SubmitHoursModal";

export default function SubmitHoursButton({
  overrideStyle,
  overloadedClasses,
  candidate,
  sectionDate,
  setSubmitted,
}) {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(openModal(
      <SubmitHoursModal candidateId={candidate.id} {...{ setSubmitted, sectionDate }} />
    ));
  };

  const disabled = !candidate.attributes.payPeriodsCount;

  return (
    <Tooltip title={disabled ? "Adding hours is available after the contract start date" : ""}>
      <span>
        <Button
          variant="primary"
          color="blue"
          onClick={handleClick}
          style={overrideStyle || {}}
          overloadedClasses={overloadedClasses}
          data-cy="submit-hours-button"
          disabled={disabled}
        >
          Add Hours
        </Button>
      </span>
    </Tooltip>
  );
}
