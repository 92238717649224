import React, { Component } from "react";
import { connect } from "react-redux";

class HeaderSub extends Component {
  renderContent() {
    const { content } = this.props;
    if (typeof content === "string" || content instanceof String) {
      return <span className="nav-text">{this.props.content}</span>;
    }
    return this.props.content;
  }

  render() {
    return (
      <div className="subnav-row">
        <div style={{ maxWidth: 1948 }}>
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  content: state.ui.subNav.content
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderSub);
