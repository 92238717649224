import { Formik } from "formik";
import { isEqual } from "lodash";
import find from "lodash/find";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import moment from "moment";
import React, {
  useCallback, useEffect, useMemo, useState
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchPayPeriods,
  updatePayPeriod,
} from "../../../../../../actions/recruiters/payPeriodActions";
import { clearModal } from "../../../../../../actions/shared/uiActions";
import { makeGetFilteredContracts } from "../../../../../../selectors/contractSelector";
import { makeGetFilteredPayPeriods } from "../../../../../../selectors/payPeriodSelectors";
import { makeGetCandidate } from "../../../../../../selectors/recruiter/candidateSelectors";
import { mapKeysToSnakeCase } from "../../../../../../util/formatHelpers";
import Button from "../../../../../forms/custom/Button";
import ActionModalContainer from "../../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../../General/ActionModal/ActionModalHeader";
import useSnackbar from "../../../../../General/useSnackbar";

import PayPeriodForm from "./PayPeriodForm";
import { buildInitialValues, validationSchema, transformValues } from "./utils";

function formatDate(date) {
  return moment(date).format("ddd. MMM D, YYYY");
}

export default function SubmitHoursModal({
  candidateId,
  setSubmitted = () => {},
  sectionDate,
  title = "Add Hours Worked"
}) {
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();
  const [edit, setEdit] = useState(false);
  const [submissionError, setSubmissionError] = useState("");

  const [selectedPayPeriod, setSelectedPayPeriod] = useState();
  const [payPeriodOptions, setPayPeriodOptions] = useState();

  const getCandidate = useMemo(makeGetCandidate, []);
  const candidate = useSelector(
    (state) => getCandidate(state, candidateId),
    (previousValue, currentValue) => previousValue.id === currentValue.id
  );

  const getContracts = useMemo(makeGetFilteredContracts, []);
  const contract = useSelector(
    (state) => getContracts(state, { candidateId })[0],
    isEqual
  );

  const getPayPeriods = useMemo(makeGetFilteredPayPeriods, []);
  const payPeriods = useSelector(
    (state) => orderBy(
      getPayPeriods(state, { contractId: contract?.id }),
      (payPeriod) => payPeriod.attributes.startDate
    )
      .reverse(),
    isEqual
  );

  const payPeriod = useSelector(
    (state) => find(getPayPeriods(state), { id: selectedPayPeriod?.value }),
    isEqual
  );

  const initialValues = useMemo(() => {
    if (payPeriod) {
      return buildInitialValues(payPeriod);
    }
    return null;
  }, [payPeriod]);

  useEffect(() => {
    dispatch(fetchPayPeriods({ candidateId }));
  }, [dispatch, candidateId]);

  useEffect(() => {
    const options = map(payPeriods, (payPeriod) => ({
      value: payPeriod.id,
      label: `${formatDate(payPeriod.attributes.startDate)} - ${formatDate(
        payPeriod.attributes.endDate
      )}`,
    }));
    setPayPeriodOptions(options);

    let period = 0;
    if (sectionDate) {
      period = options.findIndex(({ label }) => (
        moment(sectionDate).isSame(moment(label.split(" - ")[0]))
      )) ?? 0;
    }
    setSelectedPayPeriod(options[period]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract?.id]);

  useEffect(() => {
    if (payPeriod?.attributes) {
      setEdit(!payPeriod.attributes.submittedAt);
    }
  }, [payPeriod?.attributes]);

  useEffect(() => {
    setSubmissionError("");
  }, [selectedPayPeriod?.value]);

  const submit = (values) => {
    setSubmissionError("");
    return dispatch(
      updatePayPeriod(
        selectedPayPeriod.value,
        mapKeysToSnakeCase(transformValues(values))
      )
    )
      .then(() => {
        const message = (!!payPeriod.attributes.submittedAt)
          ? "Changes to hours have been saved"
          : `Hours submitted for ${selectedPayPeriod.label}`;
        showMessage(message);
        dispatch(clearModal());
        setSubmitted(true);
      })
      .catch((error) => {
        if (error.response?.status === 409) {
          setSubmissionError("Hours have already been approved.\nPlease refresh the page.");
        } else {
          showMessage("An error occurred, try again or contact support.");
        }
      });
  };

  const customButtons = useCallback(({
    handleSubmit, isSubmitting, payPeriod, touched, resetForm
  }) => {
    // When pay period has not been submitted or has already been approved
    if (!payPeriod.attributes.submittedAt || !!payPeriod.attributes.approvedAt) {
      return (
        <Button
          color="blue"
          variant="primary"
          onClick={handleSubmit}
          disabled={isSubmitting || !!payPeriod.attributes.approvedAt}
          data-cy="action-modal-button-submit"
        >
          Submit for Approval
        </Button>
      );
    }
    // When pay period has been submitted but not approved
    if (!edit) {
      return (
        <Button
          color="blue"
          variant="secondary"
          onClick={() => setEdit(true)}
          data-cy="action-modal-button-edit"
        >
          Edit Hours
        </Button>
      );
    }
    return (
      <div>
        <Button
          color="blue"
          variant="secondary"
          onClick={() => {
            setEdit(false);
            setSubmissionError("");
            resetForm();
          }}
          data-cy="action-modal-button-cancel"
          style={{ marginRight: 12 }}
        >
          Cancel
        </Button>
        <Button
          color="blue"
          variant="primary"
          onClick={handleSubmit}
          disabled={isSubmitting || Object.keys(touched).length === 0}
          data-cy="action-modal-button-submit"
        >
          Save
        </Button>
      </div>
    );
  }, [edit]);

  if (!payPeriod || !initialValues) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
      enableReinitialize
    >
      {({
        submitCount, handleSubmit, isSubmitting, errors, touched, resetForm
      }) => (
        <ActionModalContainer color="blue" DialogProps={{ disableEscapeKeyDown: true }}>
          <ActionModalHeader
            title={title}
            subtitle={`${candidate?.attributes?.firstName} ${candidate?.attributes?.lastName}`}
          />
          <ActionModalContent>
            <p>
              Use this form to submit hours worked. These hours will be
              presented to the timecard approver. Once approved, an invoice will
              be sent to the client.
            </p>
            <PayPeriodForm {...{
              candidate, payPeriod, selectedPayPeriod, setSelectedPayPeriod, payPeriodOptions, edit
            }}
            />
          </ActionModalContent>
          <ActionModalFooter
            customButtons={customButtons({
              isSubmitting, handleSubmit, payPeriod, touched, resetForm
            })}
            submissionError={
              Boolean(submissionError) || (submitCount > 0 && Object.keys(errors).length > 0)
            }
            customErrorMessage={submissionError}
            isSubmitting={isSubmitting}
            overrideErrorStyles={{ width: 400 }}
          />
        </ActionModalContainer>
      )}
    </Formik>
  );
}
