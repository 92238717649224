import isEmpty from "lodash/isEmpty";
import React from "react";
import { Row, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { setSubNav } from "../../../actions/shared/uiActions";
import { getFeatureFlags } from "../../../selectors/featureFlagSelector";
import { getCurrentIndividual } from "../../../selectors/individualSelectors";
import { getCurrentOrganization } from "../../../selectors/organizationSelectors";
import ExampleComponent from "../../General/ExampleComponent";

import NavigationTabs from "./NavigationTabs/NavigationTabs";
import UserSection from "./UserSection/UserSection";

class HeaderPrimary extends React.Component {
  renderSubNav() {
    return (
      <div
        style={{ height: "65px", backgroundColor: "#008dae", fontSize: "30px" }}
      >
        <span className="nav-text" style={{ display: "flex" }}>
          <span style={{ display: "flex" }}>
            <h4 className="white" style={{ display: "flex" }}>
              TALK Agency Directory Profile
            </h4>
          </span>
        </span>
      </div>
    );
  }

  renderLogoAndSubNav() {
    const {
      currentIndividual, loaded, location, setSubNav
    } = this.props;

    const isPublicSignup = Boolean(
      location.pathname.match(
        /^\/public\/(sign-up|employer-registration|legacy-agency-activation|employer-direct-registration|talk-employer-registration)\/?.*$/
      )
    );

    const isTalkOnly =
      (currentIndividual?.agreedToTalkTerms &&
        !currentIndividual?.agreedToRfiTerms) ||
      location.pathname === "/talk-login" ||
      location.pathname === "/public/talk-agency-registration";

    if (isTalkOnly) {
      setSubNav(this.renderSubNav());
    }

    const logoClass = isTalkOnly
      ? "talk-only-logo"
      : isPublicSignup
        ? "logo-full"
        : "logo";

    const redirectProps = isTalkOnly
      ? {
        href: "https://talktalent.com/agency-directory/for-agencies",
        target: "_blank",
      }
      : loaded
        ? { href: "/app" }
        : { href: "https://www.recruitifi.com/", target: "_blank" };

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          minWidth: 64,
          maxWidth: "148px",
          padding: "0",
          margin: "0 24px 0 12px",
        }}
      >
        <a
          title={isTalkOnly ? "TALK" : "RecruitiFi"}
          className={logoClass}
          style={{
            display: "block",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: isTalkOnly ? "44px" : isPublicSignup ? "72px" : "54px",
            width: isTalkOnly ? "115px" : isPublicSignup ? "148px" : "100%",
            backgroundPosition: isPublicSignup ? "center" : "",
          }}
          {...redirectProps}
        >
          {" "}
        </a>
      </div>
    );
  }

  renderOrganizationLogo() {
    const { employer_organization, organization_avatar_xs } =
      this.props.publicRecruiterSubmission;

    if (organization_avatar_xs) {
      return (
        <img alt={`${employer_organization}`} src={organization_avatar_xs} />
      );
    }
    return (
      <h4
        className="subtitle-1"
        style={{
          textAlign: "right",
          maxWidth: 180,
          fontWeight: 600,
        }}
      >
        {employer_organization}
      </h4>
    );
  }

  renderTabLinks() {
    const {
      visible, currentIndividual, currentOrganization
    } =
      this.props;

    // MEDONOW: make sure this check is correct
    if (!visible || !currentIndividual || isEmpty(currentOrganization)) { return null; }

    if (currentIndividual?.agreedToRfiTerms) {
      return <NavigationTabs />;
    }

    return null;
  }

  render() {
    if (this.props.featureFlags?.includes("example_feature_flag")) {
      return <ExampleComponent />;
    }
    return (
      <Row
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          height: "72px",
          margin: "0",
          backgroundColor: "#ffffff",
          padding: "0 24px",
        }}
      >
        <div
          style={{
            maxWidth: 1900,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: this.props.publicPage && "space-between",
              alignItems: "center",
              width: this.props.publicPage && "100%",
            }}
          >
            {this.renderLogoAndSubNav()}
            {this.props.publicPage && this.props.publicRecruiterSubmission && (
              <Visible xs sm>
                {this.renderOrganizationLogo()}
              </Visible>
            )}
            {this.renderTabLinks()}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexGrow: 1,
            }}
          >
            {!this.props.publicPage && <UserSection />}
          </div>
        </div>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const currentIndividual = getCurrentIndividual(state);
  const currentOrganization = getCurrentOrganization(state);
  const featureFlags = getFeatureFlags(state);
  return {
    currentIndividual,
    currentOrganization,
    featureFlags,
    loaded: Boolean(currentIndividual),
    publicPage: state.ui.public,
    publicRecruiterSubmission: state.recruiterSubmissions.publicSubmission,
    visible: state.ui.headerMenu.visible,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSubNav: (content) => dispatch(setSubNav(content)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderPrimary)
);
