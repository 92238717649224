import React, { useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { deleteCandidate } from "../../../../../actions/recruiters/candidateActions";
import { fetchJobcastRequest } from "../../../../../actions/recruiters/jobcastActions";
import { clearModal, clearProfileDrawer } from "../../../../../actions/shared/uiActions";

import ActionModalContainer from "../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../General/ActionModal/ActionModalHeader";
import withSnackbar from "../../../../General/withSnackbar";

function DeleteCandidateModal({ candidate, snackbar }) {
  const [isSubmitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();

  const fullName = candidate.attributes.name;

  const modalContent = `Deleting a candidate's submission will disable the confirmation process,
    preventing this candidate from being presented to the employer for this job. You will also
    receive an additional submission slot.`;

  useEffect(() => {
    if (isSubmitting) {
      let successMessage = `${fullName} has been deleted`;

      const requestUpdateId = candidate.attributes.requestId;

      dispatch(clearProfileDrawer());

      dispatch(deleteCandidate(candidate)).then(({ data }) => {
        if (data.coin_credit) {
          successMessage = `${successMessage} and ${data.coin_credit} Community Coin(s) have been refunded to your balance.`;
        }

        setSubmitting(false);
        dispatch(clearModal());
        snackbar.showMessage(successMessage);
        dispatch(fetchJobcastRequest(requestUpdateId));
      });
    }
  }, [isSubmitting, snackbar, fullName, dispatch, candidate]);

  const handleSubmit = useCallback(() => setSubmitting(true), []);

  return (
    <ActionModalContainer color="red">
      <ActionModalHeader title="Delete Candidate Submission" subtitle={fullName} />
      <ActionModalContent>
        {modalContent}
      </ActionModalContent>
      <ActionModalFooter
        actionText="Delete"
        handleAction={handleSubmit}
        isSubmitting={isSubmitting}
      />
    </ActionModalContainer>
  );
}

export default withSnackbar()(DeleteCandidateModal);
