import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import { useFormikContext } from "formik";
import sum from "lodash/sum";
import moment from "moment";
import React, { useMemo } from "react";
import { Row, Col } from "react-grid-system";

import SectionHeader from "../../../../../employer/JobCasts/view/Candidates/actions/modals/Hire/SectionHeader";
import DropdownSelect from "../../../../../forms/custom/DropdownSelect";
import FormContextNumberInput from "../../../../../forms/formik/FormContextInputs/FormContextNumberInput";
import FormContextTextField from "../../../../../forms/formik/FormContextInputs/FormContextTextField";

import { customSubTextFieldProps, formatter, payPeriodStatusMessage } from "../utils";

import TimesheetHoursTable from "./TimesheetHoursTable";

const useStyles = makeStyles({
  additionalBilling: {
    '& .MuiInputBase-root:not(.Mui-focused)': {
      color: 'inherit'
    }
  },
});

const disabledProps = (disabled) => (disabled ? {
  InputProps: {
    style: { color: "#90A4AE !important" },
  },
  InputLabelProps: { className: "disabled-input-label", shrink: true },
} : {});

export default function PayPeriodForm(props) {
  const { values: { timesheetHours, additionalBilling } } = useFormikContext();
  const classes = useStyles();
  const candidate = props.candidate.attributes;
  const payPeriod = props.payPeriod.attributes;
  const {
    selectedPayPeriod, setSelectedPayPeriod, payPeriodOptions, edit
  } = props;

  const [totalBill, totalEarn] = useMemo(() => {
    const standardHours = timesheetHours.find(({ id }) => id === "Standard");
    const overtimeHours = timesheetHours.find(({ id }) => id === "Overtime");
    const doubleTimeHours = timesheetHours.find(({ id }) => id === "Double Time");

    const rateHours = sum([
      standardHours?.total ?? 0,
      (overtimeHours?.total ?? 0) * 1.5,
      (doubleTimeHours?.total ?? 0) * 2,
    ]);

    const totalBill = (rateHours * candidate.hourlyBillRate) + (additionalBilling ?? 0);
    const totalEarn = totalBill * (1.0 - candidate.tempRfiTakeRate);
    return [totalBill, totalEarn];
  }, [additionalBilling, candidate, timesheetHours]);

  return (
    <>
      <SectionHeader title="Hours Worked Submission" />
      <Row style={{ padding: "0 0 12px 0" }}>
        <Col xs={6}>
          <DropdownSelect
            id="pay_periods"
            value={selectedPayPeriod}
            options={payPeriodOptions}
            onChange={(option) => setSelectedPayPeriod(option)}
            label="Select Work Week"
          />
        </Col>
        {payPeriod.editedAt && (
        <Col xs={6}>
          <TextField
            {...customSubTextFieldProps}
            id="payperiodEditedAt"
            label="Last Updated"
            value={moment(payPeriod.editedAt).format("MMM D, YYYY")}
          />
        </Col>
        )}
      </Row>
      <Row style={{ padding: "0 0 12px 0" }}>
        <Col>
          <TextField
            {...customSubTextFieldProps}
            id="payperiod-status"
            label="Status"
            value={payPeriodStatusMessage({ attributes: payPeriod }, true)}
            multiline
          />
        </Col>
      </Row>
      <TimesheetHoursTable disabled={!edit} />
      <Row style={{ padding: "12px 0" }}>
        <Col md={4}>
          <FormContextNumberInput
            disabled={!edit}
            decimalScale={2}
            label="Additional Billing"
            id="additionalBilling"
            className={classes.additionalBilling}
            InputProps={{
              startAdornment: "$",
              inputProps: { max: 5000 }
            }}
          />
        </Col>
        <Col>
          <FormContextTextField
            disabled={!edit}
            id="additionalBillingReason"
            label="Additional Billing Explanation"
            {...disabledProps(!edit)}
            inputProps={{
              maxLength: 1000
            }}
          />
        </Col>
      </Row>
      <Row style={{ padding: "0 0 12px 0" }}>
        <Col md={4}>
          <TextField
            {...customSubTextFieldProps}
            id="total-bill"
            label="Total Bill"
            value={formatter.format(
              (!edit && (payPeriod.employerCollectionAmount || 0) > 0)
                ? payPeriod.employerCollectionAmount
                : totalBill
            )}
          />
        </Col>
        <Col md={4}>
          <TextField
            {...customSubTextFieldProps}
            id="total-earn"
            label="Total Earn"
            value={formatter.format(
              (!edit && (payPeriod.recruiterPayoutAmount || 0) > 0)
                ? payPeriod.recruiterPayoutAmount
                : totalEarn
            )}
          />
        </Col>
      </Row>
    </>
  );
}
