import find from "lodash/find";

export const TEMP_SCOPE = "temp";
export const PERM_SCOPE = "perm";

// employer route keys
export const JOBCASTS = "jobcasts";
export const CONTRACT_JOBCASTS = "contract_jobcasts";
export const CONTRACT_AGENCIES = "contract_agencies";
export const CONTRACTS = "contracts";
export const AGENCIES = "agencies";
export const REPORTS = "reports";

export const EMPLOYER_TAB_OPTIONS = {
  [JOBCASTS]: {
    key: JOBCASTS,
    label: "JobCasts",
    exclusiveScope: PERM_SCOPE,
    isScopedDefault: true,
  },
  [CONTRACT_JOBCASTS]: {
    key: CONTRACT_JOBCASTS,
    label: "Roles",
    exclusiveScope: TEMP_SCOPE,
    isScopedDefault: true,
  },
  [CONTRACTS]: {
    key: CONTRACTS,
    label: "Contracts",
    exclusiveScope: TEMP_SCOPE,
    handleClick: (history) => history.push("/contracts/open"),
  },
  [AGENCIES]: {
    key: AGENCIES,
    label: "Agencies",
    exclusiveScope: PERM_SCOPE,
  },
  [CONTRACT_AGENCIES]: {
    key: CONTRACT_AGENCIES,
    label: "Agencies",
    exclusiveScope: TEMP_SCOPE,
  },
  [REPORTS]: {
    key: REPORTS,
    label: "Reports",
    exclusiveScope: null,
  },
};

// recruiter route keys
export const MY_JOBCASTS = "my-jobcasts";
export const MY_CANDIDATES = "my-candidates";
export const TALK_PROFILE = "talk-profile";
export const TALK_PROFILE_V2 = "talk-profile-v2";
export const HELP = "help";
export const MY_CONTRACT_JOBCASTS = "my-contract-jobcasts";
export const MY_CONTRACT_CANDIDATES = "my-contract-candidates";
export const MY_CONTRACTS = "my-contracts";

export const RECRUITER_TAB_OPTIONS = {
  [MY_JOBCASTS]: {
    key: MY_JOBCASTS,
    label: "My JobCasts",
    exclusiveScope: PERM_SCOPE,
    isScopedDefault: true,
  },
  [MY_CANDIDATES]: {
    key: MY_CANDIDATES,
    label: "My Candidates",
    exclusiveScope: PERM_SCOPE,
  },
  [TALK_PROFILE]: {
    key: TALK_PROFILE,
    label: "Talk Profile",
    exclusiveScope: PERM_SCOPE,
  },
  [TALK_PROFILE_V2]: {
    key: TALK_PROFILE,
    label: "Talk Profile",
    exclusiveScope: PERM_SCOPE,
    handleClick: () => {
      window.open("/app/talk-profile", "_blank");
    },
  },
  [MY_CONTRACT_JOBCASTS]: {
    key: MY_CONTRACT_JOBCASTS,
    label: "My Roles",
    exclusiveScope: TEMP_SCOPE,
    isScopedDefault: true,
    handleClick: (history) => history.push("/my-contract-jobcasts/"),
  },
  [MY_CONTRACT_CANDIDATES]: {
    key: MY_CONTRACT_CANDIDATES,
    label: "My Candidates",
    exclusiveScope: TEMP_SCOPE,
    handleClick: (history) => history.push("/my-contract-candidates/"),
  },
  [MY_CONTRACTS]: {
    key: MY_CONTRACTS,
    label: "My Contracts",
    exclusiveScope: TEMP_SCOPE,
    handleClick: (history) => history.push("/my-contracts/open"),
  },
};

export const extractKeyFromPath = (pathname = window.location.pathname) => {
  const pathArray = pathname.split("/");

  // get first relevant param from pathArray
  const key = find(pathArray, (path) => !["app", ""].includes(path));

  return key;
};

export const determineScopeFromPath = (pathname = window.location.pathname) => {
  if (pathname.match(/contract[_s]*/)) return TEMP_SCOPE;

  const key = extractKeyFromPath(pathname);

  const tabItem = EMPLOYER_TAB_OPTIONS[key] || RECRUITER_TAB_OPTIONS[key];

  return tabItem?.exclusiveScope || PERM_SCOPE;
};
