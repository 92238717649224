import normalize from "json-api-normalizer";

import { batch } from "react-redux";

import { POST, PATCH, DELETE } from "../../util/apiHelpers";
import { receiveJobcasts } from "../shared/jobcastActions";

export const RECEIVE_COST_CENTERS = "RECEIVE_COST_CENTERS";
export const RECEIVE_COST_CENTER = "RECEIVE_COST_CENTER";
export const REMOVE_COST_CENTER = "REMOVE_COST_CENTER";

export const createCostCenter = (formData) => (dispatch) => POST("/api/v3/employer/cost_centers", formData)
  .then((res) => {
    const normalized = normalize(res.data);
    dispatch(receiveCostCenter(normalized.costCenter[res.data.data.id]));
    return Promise.resolve();
  })
  .catch((error) => Promise.reject(error));

export const deleteCostCenter = (id) => (dispatch) => DELETE(`/api/v3/employer/cost_centers/${id}`)
  .then((res) => {
    const costCenter = JSON.parse(res.data.cost_center);
    const jobcasts = JSON.parse(res.data.jobcasts);
    const normalizedJobcasts = normalize(jobcasts);
    const normalizedCostCenter = normalize(costCenter);

    return batch(() => {
      dispatch(receiveJobcasts(normalizedJobcasts.jobcast));
      dispatch(removeCostCenter(normalizedCostCenter.costCenter));
    });
  })
  .catch((error) => Promise.reject(error));

export const updateCostCenter = (formData) => (dispatch) => PATCH(`/api/v3/employer/cost_centers/${formData.id}`, formData)
  .then((res) => {
    const normalized = normalize(res.data);
    dispatch(receiveCostCenter(normalized.costCenter[res.data.data.id]));
    return Promise.resolve();
  })
  .catch((error) => Promise.reject(error));

export const receiveCostCenters = (costCenters) => ({
  type: RECEIVE_COST_CENTERS,
  payload: costCenters,
});

export const receiveCostCenter = (costCenter) => ({
  type: RECEIVE_COST_CENTER,
  payload: costCenter,
});

export const removeCostCenter = (id) => ({
  type: REMOVE_COST_CENTER,
  payload: id,
});

export const getCostCenters = (state) => state.costCenters.items;
