import { makeStyles } from "@material-ui/core";
import React, {
  memo, useCallback, useEffect, useRef, useState
} from "react";
import AnimatedNumbers from "react-animated-numbers";
import { useSelector } from "react-redux";

import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import usePrevious from "../../../General/customHooks/usePrevious";
import CommunityCoinIcon from "../../../shared/Icons/CommunityCoinIcon";

const styles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    border: "1px solid #B0BEC5",
    borderRadius: "5px",
    padding: "4px 10px",
    fontWeight: 600,
    marginRight: "10px",
    listStyle: "none",
    textAlign: "center",
  },
  balanceContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  balanceAmount: ({ fontColor }) => ({
    fontSize: 14,
    fontWeight: 450,
    color: fontColor,
  }),
  iconContainer: {
    width: 20,
    display: "flex",
    alignItems: "center",
  },
  label: {
    color: "#B0BEC5",
    fontWeight: 600,
    fontSize: 10,
    lineHeight: "8px",
    letterSpacing: "1px",
  },
}));

const AnimatedBalanceAmount = ({ amount }) => {
  const springConfigs = useCallback((_number, index) => {
    // less significant digit gets more tension/movement
    const multiplier = String(amount).length - index;

    return { mass: 5, tension: 250 * multiplier, friction: 100 };
  }, [amount]);

  return (
    <AnimatedNumbers
      includeComma
      animateToNumber={amount}
      configs={springConfigs}
    />
  );
};

const MemoizedAnimatedBalanceAmount = memo(AnimatedBalanceAmount);

function CommunityCoinsBalance() {
  const currentIndividual = useSelector(getCurrentIndividual);
  const [fontColor, setFontColor] = useState("#37474F");
  const { communityCoinBalance } = currentIndividual;
  const timeoutId = useRef(null);

  const prevCommunityCoinBalance = usePrevious(communityCoinBalance);

  useEffect(() => {
    if (prevCommunityCoinBalance && prevCommunityCoinBalance !== communityCoinBalance) {
      clearTimeout(timeoutId?.current);

      setFontColor(communityCoinBalance > prevCommunityCoinBalance ? "#4CAF50" : "#F44336");
      const currTimeoutId = setTimeout(() => {
        setFontColor("#37474F");
      }, 3000);
      timeoutId.current = currTimeoutId;
    }
  }, [communityCoinBalance, prevCommunityCoinBalance]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId?.current);
    };
  }, []);

  const classes = styles({ fontColor });

  return (
    <div className={classes.container}>
      <div className={classes.balanceContainer}>
        <div className={classes.balanceAmount}>
          <MemoizedAnimatedBalanceAmount amount={communityCoinBalance} />
        </div>
        <div className={classes.iconContainer}>
          <CommunityCoinIcon />
        </div>
      </div>
      <div className={classes.label}>
        COINS
      </div>
    </div>
  );
}

export default CommunityCoinsBalance;
