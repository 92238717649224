import React from "react";
import { Row, Col } from "react-grid-system";
import { useSelector } from "react-redux";

import { getCurrentIndividual } from "../../../../../../selectors/individualSelectors";

import PageCardRowHeader from "../../../../../General/PageCardRowHeader";
import FieldSection, { Field } from "../../ResponsesTab/FieldSection";

import PayPeriodsTable from "./PayPeriodsTable";

function TotalHoursField({ contract }) {
  return (
    <Field label="Total Hours Worked">{contract.attributes.totalHours}</Field>
  );
}

function TotalNonStandardHoursField({ contract }) {
  return (
    <Field label="Non-Std. Hours">
      {contract.attributes.totalNonStandardHours}
    </Field>
  );
}

function TotalBilledField({ contract }) {
  return (
    <Field label="Total Billed">
      $
      {parseFloat(contract.attributes.totalEmployerCollectionAmount).toFixed(2)}
    </Field>
  );
}

function TotalEarnedField({ contract }) {
  return (
    <Field label="Total Earned">
      $
      {parseFloat(contract.attributes.totalRecruiterPayoutAmount).toFixed(2)}
    </Field>
  );
}

function AvgWeeklyBillField({ contract }) {
  return (
    <Field label="Average Weekly Bill">
      $
      {parseFloat(contract.attributes.averageWeeklyBill).toFixed(2)}
    </Field>
  );
}

function PaymentHistory({ contract }) {
  const currentIndividual = useSelector(getCurrentIndividual);

  const isEmployer = currentIndividual.employer;

  return (
    <>
      <PageCardRowHeader title="Payment History" />
      <FieldSection>
        <Row>
          <Col xs={3}>
            <TotalHoursField contract={contract} />
          </Col>
          <Col xs={3}>
            <TotalNonStandardHoursField contract={contract} />
          </Col>
          <Col xs={3}>
            {isEmployer ? (
              <TotalBilledField contract={contract} />
            ) : (
              <TotalEarnedField contract={contract} />
            )}
          </Col>
          <Col xs={3}>
            <AvgWeeklyBillField contract={contract} />
          </Col>
        </Row>
        <Row style={{ display: "inherit", marginRight: 15 }}>
          <PayPeriodsTable {...{ contract, isEmployer }} />
        </Row>
      </FieldSection>
    </>
  );
}

export default PaymentHistory;
