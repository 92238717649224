import { withStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import EditIcon from '@material-ui/icons/Edit';
import SkipBackward from "mdi-material-ui/SkipBackward";
import TransitTransfer from "mdi-material-ui/TransitTransfer";
import React from "react";
import { connect } from "react-redux";

import { openModal } from "../../../../../../actions/shared/uiActions";
import { ReactComponent as FileDownload } from '../../../../../../assets/images/file-download.svg';
import { getCurrentIndividual } from "../../../../../../selectors/individualSelectors";
import ActionMenu, {
  DisabledActionMenu,
} from "../../../../../General/ActionMenu";

import EditContractModal from "../../../../../shared/Contracts/EditContractModal";
import ViolationModal from "../../../../Agencies/modals/ViolationModal/ViolationModal";
import EditHireDetailsModal from "../../../../Billing/EditHireDetailsModal/EditHireDetailsModal";

import BackModal from "./modals/BackModal";
import HireModal from "./modals/HireModal";
import ShareModal from "./modals/ShareModal";
import TransferModal from "./modals/TransferModal";

const SHARE = "share";
const TRANSFER = "transfer_between_jobs";
const DOWNLOAD_CANDIDATE_PROFILE = "download_candidate_profile";
const BACK = "move_backward";
const EDIT_HIRE_DETAILS = "edit_hire_details";
const EDIT_CONTRACT = "edit_contract";
const HIRE = "hire";
const REPORT = "report";

export function DisabledCandidateActionMenu() {
  return (
    <DisabledActionMenu
      TooltipProps={{
        id: "tooltip-icon",
        title: (
          <span style={{ fontSize: "12px" }}>
            Restore this candidate to take additional actions
          </span>
        ),
        placement: "left",
      }}
    />
  );
}

const CandidateActionMenu = withStyles({ menuPaper: { width: 250 } })(
  ({
    candidate,
    currentIndividual,
    candidate: {
      attributes: {
        status, jobcastId, placementId, recruiterId, recruiterFullName
      },
    },
    openModal,
    classes,
  }) => {
    const handleActionSelection = (actionName) => {
      switch (actionName) {
        case SHARE:
          openModal(
            <ShareModal
              selectedCandidateId={candidate.id}
              jobcastId={jobcastId}
            />
          );
          break;
        case TRANSFER:
          openModal(<TransferModal candidate={candidate} />);
          break;
        case DOWNLOAD_CANDIDATE_PROFILE:
          if (candidate.attributes.candidateInfoPdf) {
            fetch(candidate.attributes.candidateInfoPdf)
              .then(resp => resp.blob())
              .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const infoLink = document.createElement('a');
                infoLink.href = url;
                infoLink.download = `${candidate.attributes.firstName}_${candidate.attributes.lastName}_recruitifi_profile.pdf`;
                infoLink.click();
              });
          } else {
            fetch(candidate.attributes.resume)
              .then(resp => resp.blob())
              .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const resumeLink = document.createElement('a');
                resumeLink.href = url;
                resumeLink.download = `${candidate.attributes.firstName}_${candidate.attributes.lastName}_resume.pdf`;
                resumeLink.click();
              });
          }
          break;
        case BACK:
          openModal(<BackModal candidate={candidate} />);
          break;
        case EDIT_HIRE_DETAILS:
          openModal(<EditHireDetailsModal {...{ placementId }} />);
          break;
        case EDIT_CONTRACT:
          openModal(<EditContractModal candidate={candidate} />);
          break;
        case HIRE:
          openModal(<HireModal candidate={candidate} />);
          break;
        case REPORT:
          openModal(<ViolationModal recruiterId={recruiterId} recruiterName={recruiterFullName} />);
          break;
        default:
      }
    };

    const determineActions = (currentIndividual) => {
      if (currentIndividual.recruiter) {
        return [{ name: DOWNLOAD_CANDIDATE_PROFILE, icon: <FileDownload /> }];
      }

      const actions = [];

      switch (status) {
        case "hired":
          return [
            { name: EDIT_HIRE_DETAILS, icon: "edit" },
            { name: REPORT, icon: "flag" }
          ];
        case "rejected":
          return [
            { name: TRANSFER, icon: <TransitTransfer /> },
            { name: REPORT, icon: "flag" }
          ];
        case "reviewing":
        case "interviewing":
          if (!candidate.attributes.temp) {
            actions.push({ name: HIRE, icon: <Check /> });
          }
          if (status === "interviewing") {
            actions.push({ name: BACK, icon: <SkipBackward /> });
          }
          break;
        case "offer":
          if (candidate.attributes.temp) {
            actions.push({ name: EDIT_CONTRACT, icon: <EditIcon /> });
          }
          actions.push({ name: BACK, icon: <SkipBackward /> });
          break;
        default:
          break;
      }

      actions.push({ name: SHARE, icon: "share-variant" });
      actions.push({ name: TRANSFER, icon: <TransitTransfer /> });
      actions.push({ name: DOWNLOAD_CANDIDATE_PROFILE, icon: <FileDownload /> });
      actions.push({ name: REPORT, icon: "flag" });

      return actions;
    };

    return (
      <ActionMenu
        actions={determineActions(currentIndividual)}
        dataCy="candidate-action-menu"
        handleActionSelection={(actionName) => handleActionSelection(actionName)}
        overrideMenuStyles={{ marginLeft: 0, marginTop: 0 }}
        menuClasses={{ paper: classes.menuPaper }}
      />
    );
  }
);

const mapStateToProps = (state) => ({
  currentIndividual: getCurrentIndividual(state),
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modalContent) => dispatch(openModal(modalContent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateActionMenu);
