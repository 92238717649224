import { withFormik } from "formik";
import React from "react";
import { connect } from "react-redux";

import { deletePreferredLink } from "../../../../actions/employers/agencyActions";
import {
  clearModal,
  setProfileDrawerVisible,
} from "../../../../actions/shared/uiActions";
import { makeGetAgencyOrganization } from "../../../../selectors/employer/agencySelectors";
import ActionModalContainer from "../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../General/ActionModal/ActionModalHeader";
import withSnackbar from "../../../General/withSnackbar";

class DeleteAgencyModal extends React.PureComponent {
  state = { loading: false };

  renderContent() {
    return (
      <>
        <div style={{ textAlign: "center", color: "#FF6D6D" }}>
          Are you sure you want to delete this preferred agency link?
        </div>
        <ul className="small" style={{ marginBottom: 0 }}>
          <li>
            All teammates will have this agency removed from their list of
            preferred agencies
          </li>
          <li>
            All future JobCasts this agency receives will be at the community
            fee
          </li>
          <li>
            All JobCasts that this agency has been invited to previously will
            remain at their preferred fee
          </li>
          <li>
            This agency will be notified of the removal of their preferred fee
          </li>
          <li>
            This agency will be removed from all "Team" and "Personal" Saved
            Agency Lists
          </li>
        </ul>
      </>
    );
  }

  render() {
    const { handleSubmit, isSubmitting, agency } = this.props;
    const { organization } = agency;

    return (
      <ActionModalContainer color="red">
        <ActionModalHeader
          title="Delete Legacy Agency Link"
          subtitle={organization.attributes.name}
        />
        <ActionModalContent>{this.renderContent()}</ActionModalContent>
        <ActionModalFooter
          actionText="Delete"
          handleAction={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </ActionModalContainer>
    );
  }
}

const DeleteAgencyModalForm = withFormik({
  handleSubmit: (_values, { props, setSubmitting }) => {
    const {
      clearModal,
      closeProfileDrawer,
      agency,
      deletePreferredLink,
      snackbar,
    } = props;

    deletePreferredLink(agency.preferredLink.id)
      .then(() => {
        setSubmitting(false);
        snackbar.showMessage("The agency has been deleted.");
      })
      .then(clearModal)
      .then(closeProfileDrawer)
      .catch(() => {});
  },
})(DeleteAgencyModal);

const makeMapStateToProps = () => {
  const getAgencyOrganization = makeGetAgencyOrganization();

  return (state, { organizationId }) => ({
    agency: getAgencyOrganization(state, organizationId),
  });
};

const mapDispatchToProps = (dispatch) => ({
  clearModal: () => dispatch(clearModal()),
  deletePreferredLink: (id) => dispatch(deletePreferredLink(id)),
  closeProfileDrawer: () => dispatch(setProfileDrawerVisible(false)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withSnackbar()(DeleteAgencyModalForm));
