import classnames from "classnames";
import merge from "lodash/merge";

import moment from "moment";

import { disabledProps } from "../../../../../util/formHelpers";

export const APPROVED_MESSAGE = "Approved by manager";
export const AWAITING_MESSAGE = "Submitted for approval";
export const ZERO_HOURS_MESSAGE = "Submitted";
export const NOT_SUBMITTED_MESSAGE = "Not submitted";

const isWithin48Hours = (dateToCheck, formattedDate) => {
  const dateChecked = moment(dateToCheck);
  const targetDate = moment(formattedDate, 'dddd, MMMM D, YYYY h:mm A z');
  const hoursDifference = Math.abs(targetDate.diff(dateChecked, 'hours'));

  return hoursDifference <= 48;
};

const getFormattedDate = (date) => {
  return date.format('dddd, MMMM D, YYYY h:mm A [EST]');
};

export function payPeriodStatusMessage(payPeriod, modal = false) {
  const {
    approvedAt,
    editedAt,
    submittedAt,
    standardHours,
    nonStandardHours,
    doubleTimeHours,
    additionalBilling,
  } = payPeriod.attributes;

  const day = (moment().isoWeekday() < 3) ? 3 : 10;
  const zeroHoursDate = getFormattedDate(moment().isoWeekday(day)
    .hours(23)
    .minutes(30)
    .utcOffset('America/New_York'));

  const nextWednesday = moment().clone().startOf('week').add(3, 'days')
    .hours(23)
    .minutes(30)
    .utcOffset('America/New_York');

  let formattedUpcomingWednesday = getFormattedDate(nextWednesday);

  const isSubmittedOrEditedWithin48Hours =
    isWithin48Hours(submittedAt, formattedUpcomingWednesday) ||
    isWithin48Hours(editedAt, formattedUpcomingWednesday);

  if (isSubmittedOrEditedWithin48Hours) {
    formattedUpcomingWednesday = getFormattedDate(nextWednesday.clone().add(7, 'days'));
  }

  if (approvedAt) {
    return APPROVED_MESSAGE;
  }

  if (submittedAt) {
    if (!standardHours && !nonStandardHours && !doubleTimeHours && !additionalBilling) {
      return modal ? `Submitted - can be edited until ${zeroHoursDate}` : ZERO_HOURS_MESSAGE;
    }

    return modal ? `Submitted - can be edited until ${formattedUpcomingWednesday} or approved by employer` : AWAITING_MESSAGE;
  }

  return NOT_SUBMITTED_MESSAGE;
}

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const customSubTextFieldProps = merge({}, disabledProps(true), {
  FormHelperTextProps: {
    classes: {
      root: classnames("caption", "grey", "noMargin"),
    },
  },
  style: { marginBottom: 0 },
});
